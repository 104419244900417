import React, { useState, useEffect, useContext } from "react";
import { Provider } from 'react-redux';
import { View, StyleSheet } from 'react-native-web';
import { SafeAreaProvider, useSafeAreaInsets } from 'react-native-safe-area-context';

import { UIPopoverBackground } from '@tonlabs/uikit.navigation_legacy';
import { PortalManager } from '@tonlabs/uikit.layout';
import {
    ThemeContext,
    DarkTheme,
    LightTheme,
    useTheme,
} from '@tonlabs/uikit.themes';

import { store } from "./redux/store/configure-store";
import Navigator from './Navigator';
import Config from './config';

import "./assets/styles/bootstrap/bootstrap.min.css";
import "./assets/styles/bootstrap/_variables.scss";
import "./assets/styles/index.scss";

const styles = StyleSheet.create({
    appContainer: {
        display: 'flex',
    },
    container: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        bottom: 0,
        right: 0,
    },
});

const App = () => {
  const [isHidden, setIsHidden] = useState(true);
  const [isDarkTheme, setIsDarkTheme] = useState(Config.isLandingPage);

  useEffect(() => {
      (async () => {
          // await Configs.setup();
          setIsHidden(false);
      })();
  }, []);

  if (isHidden) {
      return null;
  }

  return (
    <Provider store={store}>
    <ThemeContext.Provider value={isDarkTheme ? DarkTheme : LightTheme}>
      <SafeAreaProvider>
        <UIPopoverBackground>
          <View style={[styles.container, styles.appContainer]}>
            <PortalManager>
              <Navigator />
            </PortalManager>
          </View>
        </UIPopoverBackground>
      </SafeAreaProvider>
    </ThemeContext.Provider>
    </Provider>
  );
};

export default App;

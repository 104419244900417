// @ts-nocheck
import { FunctionComponent } from "react";
import { StyleSheet, View, TouchableOpacity } from 'react-native-web';
import { UIStyle } from '@tonlabs/uikit.core';
import { UILabel, ColorVariants, TypographyVariants, UIBackgroundView } from '@tonlabs/uikit.themes';

import Config from '../../config';


const Footer: FunctionComponent<{}> = () => {
  return (
    <UIBackgroundView color={Config.isLandingPage ? ColorVariants.BackgroundPrimary : null}>
      <View style={[{alignItems: 'center'}, UIStyle.padding.default()]}>
        {Config.isLandingPage ?
          <TouchableOpacity onPress={() => window.open('https://freeton.org','_blank')}>
            <UILabel color={ColorVariants.TextTertiary} role={TypographyVariants.ParagraphFootnote}>
              {'built on Free TON'}
            </UILabel>
          </TouchableOpacity>
        :
          <UILabel color={ColorVariants.TextTertiary} role={TypographyVariants.ParagraphFootnote}>
            {`2K${(new Date()).getFullYear().toString().substring(2)}@TON Surf`}
          </UILabel>
        }
      </View>
    </UIBackgroundView>
  );
};

export default Footer;

// Auth actions
const IS_LOGGED_IN = 'IS_LOGGED_IN';
const SHOW_CONTROLLER = 'SHOW_CONTROLLER';
const SET_BACKGROUND_PRESET = 'SET_BACKGROUND_PRESET';
const SET_SCREEN_WIDTH = 'SET_SCREEN_WIDTH';
const SET_MOBILE = 'SET_MOBILE';
const NAVIGATION_MENU_CONTROLLER = 'NAVIGATION_MENU_CONTROLLER';
const SET_DEBOT = "SET_DEBOT";
const SET_PAIR = "SET_PAIR";

const SET_DEBOTS = "SET_DEBOTS";
const SET_PAIRS = "SET_PAIRS";
const SET_NORMALIZED_PAIRS = "SET_NORMALIZED_PAIRS";
const SET_ORDERBOOK = "SET_ORDERBOOK";
const SET_TRADES = "SET_TRADES";

const SELECT_ORDERBOOK_ROW = "SELECT_ORDERBOOK_ROW";
const SET_EXCHANGE_MODE = "SET_EXCHANGE_MODE";

const SET_ORDER_MESSAGE = "SET_ORDER_MESSAGE";

const SET_LINEAR_CHART = "SET_LINEAR_CHART";
const SET_PRICE_LINEAR_CHART = "SET_PRICE_LINEAR_CHART";
const SET_CANDLESTICK_CHART = "SET_CANDLESTICK_CHART";

export {
    IS_LOGGED_IN,
    SHOW_CONTROLLER,
    SET_BACKGROUND_PRESET,
    SET_SCREEN_WIDTH,
    SET_MOBILE,
    NAVIGATION_MENU_CONTROLLER,
    SET_DEBOT,
    SET_PAIR,
    SET_DEBOTS,
    SET_PAIRS,
    SET_NORMALIZED_PAIRS,
    SET_ORDERBOOK,
    SET_TRADES,
    SELECT_ORDERBOOK_ROW,
    SET_EXCHANGE_MODE,
    SET_ORDER_MESSAGE,
    SET_LINEAR_CHART,
    SET_PRICE_LINEAR_CHART,
    SET_CANDLESTICK_CHART,
};

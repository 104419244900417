// @flow
import Config from '../config';

import LandingScreen from '../screens/LandingScreen';
import TradeScreen from '../screens/TradeScreen';
import ErrorScreen from '../screens/ErrorScreen';

const error = {
    name: 'ErrorScreen',
    screen: ErrorScreen,
    path: '404',
};

const landing = {
    name: 'MainScreen',
    screen: LandingScreen,
    path: 'main',
};

const trade = {
    name: 'MainScreen',
    screen: TradeScreen,
    path: 'main',
    /*dynamicParameters: {
        tradingPair: true,
        debot: true,
    },*/
};
/*
const cv = {
    name: 'CareerCVScreen',
    screen: CareerCVScreen,
    path: 'apply',
    dynamicParameters: {
        position: true,
    },
};

const contacts = {
    name: 'ContactsScreen',
    screen: ContactsScreen,
    path: 'contacts',
};*/

function createRouting(routes) {
  const routing = {
      paths: {},
      screens: {},
  };
  routes.forEach((route) => {
      const path = {
          name: route.path || route.name,
      };
      if (route.staticParameters) {
          path.staticParameters = route.staticParameters;
      }
      if (route.dynamicParameters) {
          path.dynamicParameters = route.dynamicParameters;
      }
      routing.paths[route.name] = path;
      routing.screens[route.name] = {
          screen: route.screen,
      };
  });
  return routing;
}

export default class TONLabsRoutes {
    static routing = createRouting(Config.isLandingPage ? [
        landing,
//        error,
    ] : [
        trade,
//        error,
    ]);
//routing.paths
    static errorScreen() {
        return 'ErrorScreen';
    }

    static defaultScreen() {
        return 'MainScreen';
    }
}

// @ts-nocheck
import React from "react";
import { FunctionComponent } from "react";
import { View } from 'react-native-web';

import { UILabel, ColorVariants, TypographyVariants } from '@tonlabs/uikit.themes';
import { UIBoxButton } from '@tonlabs/uikit.controls';
import {UIStyle} from '@tonlabs/uikit.core';

const Card: FunctionComponent = (props) => (
    <View style={[UIStyle.padding.default(), props.style]}>
      <View style={UIStyle.margin.topDefault()} />
      <UILabel color={ColorVariants.TextPrimary} role={TypographyVariants.TitleMedium}>
          {props.title}
      </UILabel>
      <View style={UIStyle.margin.topTiny()} />
      <UILabel color={ColorVariants.TextSecondary} role={TypographyVariants.ParagraphText}>
          {props.text}
      </UILabel>
      <View style={UIStyle.margin.topDefault()} />
      <UIBoxButton
          onPress={()=>window.open(props.link, '_blank')}
          title={props.buttonTitle}
      />
    </View>
);

export default Card;

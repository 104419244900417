import {
  SET_PAIRS,
  SET_NORMALIZED_PAIRS,
  SET_PAIR,
  SET_DEBOTS,
  SET_DEBOT,
  SET_ORDERBOOK,
  SELECT_ORDERBOOK_ROW,
  SET_EXCHANGE_MODE,
  SET_TRADES,
  SET_ORDER_MESSAGE,
  SET_CANDLESTICK_CHART,
  SET_LINEAR_CHART,
  SET_PRICE_LINEAR_CHART
} from "./ActionTypes";

import {
  Pairs,
  PairInfo,
  DeBot,
  TradeHistory,
  OrderBookRow,
  exchangeMode,
  OrderMessage,
  LinearChart,
  CandlestickChart
} from "../../interfaces";

export const setPairs = (value: Pairs) =>
  ({
    type: SET_PAIRS,
    payload: value,
  } as const);

export const setNormalizedPairs = (value: PairInfo[]) =>
  ({
    type: SET_NORMALIZED_PAIRS,
    payload: value,
  } as const);

export const setPair = (value: PairInfo) =>
  ({
    type: SET_PAIR,
    payload: value,
  } as const);

export const setDeBots = (value: Array<DeBot>) =>
  ({
    type: SET_DEBOTS,
    payload: value,
  } as const);

export const setDeBot = (value: DeBot) =>
  ({
    type: SET_DEBOT,
    payload: value,
  } as const);

export const setOrderbook = (value: OrderBookRow[]) =>
  ({
    type: SET_ORDERBOOK,
    payload: value,
  } as const);

export const selectOrderbookRow = (value: OrderBookRow) =>
  ({
    type: SELECT_ORDERBOOK_ROW,
    payload: value,
  } as const);

export const setExchangeMode = (value: exchangeMode) =>
  ({
    type: SET_EXCHANGE_MODE,
    payload: value,
  } as const);


export const setTrades = (value: TradeHistory) =>
({
  type: SET_TRADES,
  payload: value,
} as const);


export const setOrderMessage = (value: OrderMessage) =>
({
  type: SET_ORDER_MESSAGE,
  payload: value,
} as const);


export const setLinearChart = (value: LinearChart) =>
({
  type: SET_LINEAR_CHART,
  payload: value,
} as const);

export const setPriceLinearChart = (value: LinearChart) =>
({
  type: SET_PRICE_LINEAR_CHART,
  payload: value,
} as const);


export const setCandlestickChart = (value: CandlestickChart) =>
({
  type: SET_CANDLESTICK_CHART,
  payload: value,
} as const);

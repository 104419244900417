import { Dispatch, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ActionTypes, RootState } from "../../types";
import DeBotClient, { DeBots } from "../../client/client";

import {
  Tip3Tip3PairInfo,
  Tip3TonPairInfo,
  DeBot,
  PairInfo,
  PairInfoType
 } from "../../interfaces";

import {
    setPairs,
    setNormalizedPairs,
    setPair,
    setOrderbook,
    setCandlestickChart,
    setPriceLinearChart,
    setLinearChart,
    setTrades,
    setDeBot
} from "../../redux/actions";

const requestsPairs:string[] = [];

export interface IUseHeader {
    pairs: Array<PairInfo>,
    selectedPair: PairInfo | undefined,
    selectedDeBot: DeBot | undefined,
    debots: Array<DeBot>,
    setSelectedPair: any,
    setSelectedDeBot: any,
    location: any,
}

export const useHeader = (): IUseHeader => {
  const dispatch = useDispatch<Dispatch<ActionTypes>>();
  const { pairs, normalizedPairs, selectedPair, selectedDeBot, debots } = useSelector((state: RootState) => state.dashboard);
  const readablePairs = pairs.tip3tonPairs.map((i: Tip3TonPairInfo) => ({
    name: DeBotClient.hexToUtf8(i.symbol),
    value: i.tradingPair
  }));

  const setSelectedDeBot = (value: DeBot):void => {
    if(debots) {
      const debot: DeBot | undefined = debots.find(
        (debot: DeBot) => {
          return debot ? debot.name === value.name : undefined
        }
      )
      if(debot && debot !== selectedDeBot) {
        dispatch(setOrderbook([]));
        dispatch(setCandlestickChart({}))
        dispatch(setPriceLinearChart({}))
        dispatch(setLinearChart({}))
        dispatch(setTrades({}))
        dispatch(setNormalizedPairs([]));
        // todo: cancel client's pairs request (getPairs()) which is in process!
        // otherwise returned pairs for non-actual previous selected debot will bet set.
        dispatch(setPairs({
          tip3tip3Pairs: [],
          tip3tonPairs: []
        }));
        dispatch(setDeBot(debot));
      }
    }
  }

  const setSelectedPair = (value: PairInfo):void => {
    if(pairs) {
      const pair: PairInfo | undefined = normalizedPairs.find(
        (pair: PairInfo) => {
          return pair ? pair.tradingPair === value.tradingPair : undefined
        }
      )
      if(pair && pair !== selectedPair) {
        dispatch(setPair(pair));
        dispatch(setOrderbook([]));
        dispatch(setCandlestickChart({}))
        dispatch(setPriceLinearChart({}))
        dispatch(setLinearChart({}))
        dispatch(setTrades({}))
      }
    }
  }

  const normalizePairs = (pairs: Tip3TonPairInfo[] | Tip3Tip3PairInfo[]) => {
    return pairs.map(pair => ({
      type: "symbol" in pair ? 'Tip3TonPairInfo' as PairInfoType : 'Tip3Tip3PairInfo' as PairInfoType,
      nameMinor: "symbol" in pair ? 'TON'                              : DeBotClient.hexToUtf8(pair.symbolMinor),
      nameMajor: "symbol" in pair ? DeBotClient.hexToUtf8(pair.symbol) : DeBotClient.hexToUtf8(pair.symbolMajor),

      symbolMinor: "symbol" in pair ? ''          : pair.symbolMinor,
      symbolMajor: "symbol" in pair ? pair.symbol : pair.symbolMajor,

      decimalsMinor: "symbol" in pair ? '9'           : pair.decimalsMinor,
      decimalsMajor: "symbol" in pair ? pair.decimals : pair.decimalsMajor,

      tradingPair: "symbol" in pair ? pair.tradingPair : pair.xchgTradingPair,
    }))
  }

  useEffect(() => {
    if (!selectedDeBot) return;
    //console.log('getPairs for debot: ', selectedDeBot);
    //todo: find a way to cancel it
    requestsPairs.push(selectedDeBot.title);
    DeBotClient.getPairs(selectedDeBot)
      .then((value) => {
        /*if (
            requestsPairs &&
            requestsPairs.length &&
            requestsPairs[requestsPairs.length - 1] !== selectedDeBot.title
        ) {
            return;
        }*/
        dispatch(setPairs(value));
        dispatch(setNormalizedPairs([...normalizePairs(value.tip3tonPairs), ...normalizePairs(value.tip3tip3Pairs)]));
      });
  }, [selectedDeBot]);

  let location = window.location.search;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has("tradingPair")) {
        let searchTradingPair = searchParams.get("tradingPair");
        searchTradingPair = searchTradingPair ? searchTradingPair.toLowerCase() : '';
        const presetPair = normalizedPairs.filter((pair) => pair.tradingPair === searchTradingPair );
        if (presetPair && presetPair.length && presetPair[0]) {
            setSelectedPair(presetPair[0]);
            return;
        }
    }
    dispatch(setPair(normalizedPairs[0]));
  }, [normalizedPairs, location]);

  useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      if (searchParams.has("debot")) {
          let searchDebot = searchParams.get("debot");
          searchDebot = searchDebot ? searchDebot.toLowerCase() : '';
          const presetDebot = debots.filter((debot) => debot.title.toLowerCase() === searchDebot );
          if (presetDebot && presetDebot.length && presetDebot[0]) {
              setSelectedDeBot(presetDebot[0]);
              return;
          }
      }

      if (debots.length)
          dispatch(setDeBot(debots[0]));
  }, [debots, location]);

  return useMemo(
    () => ({
        pairs: normalizedPairs,
        selectedPair: selectedPair,
        selectedDeBot,
        debots: DeBots,
        setSelectedPair,
        setSelectedDeBot,
        location,
    }),
    [readablePairs, selectedPair, selectedDeBot, DeBots],
  );
};

import { createStore, compose/*, applyMiddleware*/ } from "redux";
//import thunk from "redux-thunk";
import { Platform } from 'react-native-web';

import { RootReducer } from "../reducers/root-reducer";

//export const store = createStore(rootReducer, applyMiddleware(thunk));

const initialState = {};

let composeEnhancers = compose;
if (Platform.OS === 'web') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

export const store = createStore(
    RootReducer,
    initialState,
    composeEnhancers(),
);

import React from 'react';
import { Linking } from 'react-native-web';

import { UIScreen } from '@tonlabs/uikit.navigation_legacy';

import iconApp from '../../assets/24.png';

export default class ErrorScreen extends UIScreen {
    static navigationOptions = () => {
        return { headerShown: false, title: 'Flash Exchange' };
    }

    constructor(props: Props) {
        super(props);
    }

    // Virtual
    getProductIcon() {
        return iconApp;
    }

    // Events
    onPressBackToHome = () => {
        Linking.openURL('./main');
    };

    renderContent() {
        return '404';
    }
}

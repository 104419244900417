// @ts-nocheck
import { FunctionComponent, useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { ReactComponent as Logo } from "../../assets/images/logo-flash.svg";
import logoFlash from "../../assets/images/logo-flash.png";
import { Link } from "react-router-dom";
import { StyleSheet, View } from 'react-native-web';

import { UILabel, ColorVariants, TypographyVariants, useTheme, UILabelColors, UILabelRoles, UIBackgroundView } from '@tonlabs/uikit.themes';
import { UIStyle } from '@tonlabs/uikit.core';
import { UIAssets } from '@tonlabs/uikit.assets';
import { UILinkButtonType, UILinkButtonIconPosition, UILinkButton } from '@tonlabs/uikit.controls';

import { useHeader } from "../controllers/useHeader";
import Config from '../../config';

const Layout: FunctionComponent<{}> = (props) => {
  const { pairs, selectedPair, setSelectedPair, debots, selectedDeBot, setSelectedDeBot, location } = useHeader();
  const [expanded, setExpanded] = useState<boolean>(false);
  const theme = useTheme();

  if (Config.isLandingPage) return (
    <UIBackgroundView color={ColorVariants.BackgroundPrimary}>
                <View style={[
                    //styles.container,
                    {height: 56},
                    { borderBottomColor: theme[ColorVariants.BackgroundTertiary], borderBottomWidth: props.divider ? 1 : 0 },
                    UIStyle.padding.horizontal(),
                ]}
                >
                    <View style={[UIStyle.container.rowSpace(), UIStyle.flex.alignCenter()]}>
                      <UILinkButton
                        icon={logoFlash}
                        iconPosition={UILinkButtonIconPosition.Left}
                        type={UILinkButtonType.Menu}
                        title={'Flash Exchange'}
                        onPress={()=>window.open(`${Config.flexUrl}?debot=mainnet`,'_blank')}/>
                      <UILinkButton
                        type={UILinkButtonType.Menu}
                        title={'Launch app'}
                        icon={UIAssets.icons.ui.arrowUpRight}
                        iconPosition={UILinkButtonIconPosition.Middle}
                        onPress={()=>window.open(`${Config.flexUrl}?debot=mainnet`,'_blank')}/>
                    </View>
                </View>
    </UIBackgroundView>
  );
  return (
    <header>
      <Navbar expand="sm" expanded={expanded}>
        <Navbar.Brand>
          <Nav.Link to="/" onClick={() => setExpanded(false)} className="logo">
            <Logo/>
            <span>Flash Exchange</span>
          </Nav.Link>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : true)} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown title={selectedDeBot ? selectedDeBot.title : "Loading..."} id="net-nav-dropdown">
              {debots.map((debot, index) => <NavDropdown.Item onClick={() => {
                  setSelectedDeBot(debot);
                  window.history.pushState(null, '', `${window.location.pathname}?debot=${debot.title.toLowerCase()}`);
              }} key={index}>{debot.title}</NavDropdown.Item>)}
            </NavDropdown>
            <NavDropdown title={selectedPair ? `${selectedPair.nameMajor} / ${selectedPair.nameMinor}` : 'Loading...'} id="currency-nav-dropdown">
              {pairs.map((pair, index) => <NavDropdown.Item onClick={() => {
                  setSelectedPair(pair);
                  const debotParam = selectedDeBot ? `debot=${selectedDeBot.title.toLowerCase()}&` : '';
                  window.history.pushState(null, '', `${window.location.pathname}?${debotParam}tradingPair=${pair.tradingPair}`);
              }} key={index}>{`${pair.nameMajor} / ${pair.nameMinor}`}</NavDropdown.Item>)}
            </NavDropdown>
          </Nav>
          <Nav.Link href={Config.landingUrl} target="_blank" onClick={() => setExpanded(false)} className="btn-icon btn-icon-arrow-up-right">Home</Nav.Link>
          <Nav.Link href={Config.docsUrl} target="_blank" onClick={() => setExpanded(false)} className="btn-icon btn-icon-arrow-up-right">Help</Nav.Link>
          <Nav.Link href="https://ton.surf/get" target="_blank" className="btn-icon btn-icon-arrow-up-right" onClick={() => setExpanded(false)}>Get Surf</Nav.Link>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default Layout;

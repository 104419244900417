import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Animated, Easing } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { NavigationContainer } from '@react-navigation/native';

import TONLabsRoutes from '../../TONFlex';
//import TONLegalNotes from '../../../TONLegalNotes';

import PathMap from '../PathMap';

const Stack = createStackNavigator();

const fade = (props) => {
    const { position, scene } = props;
    const { index } = scene;
    const inputRange = [index - 1, index, index + 1];
    const opacity = position.interpolate({
        inputRange,
        outputRange: [0, 1, 0],
    });

    const transform = [{ translateX: 0 }, { translateY: 0 }];

    return {
        opacity,
        transform,
    };
};

function createNavigator(screens, initialRouteName, initialRouteParams) {
    const routeConfigs = PathMap.addPathToScreens(screens);
    const navigatorConfig = {
        initialRouteName: screens[initialRouteName] ? initialRouteName : null,
        initialRouteParams: {
            initialRoute: true,
            ...initialRouteParams,
        },
        navigationOptions: () => ({
            headerTransparent: true,
        }),
        transitionConfig: () => ({
            containerStyle: {
                backgroundColor: 'transparent',
            },
            transitionSpec: {
                duration: 1000,//UIConstant.animationDuration(),
                timing: Animated.timing,
                easing: Easing.exp,
            },
            screenInterpolator: (props) => {
                return fade(props);
            },
        }),
        cardStyle: {
            backgroundColor: 'transparent',
            shadowRadius: 0,
        },
        headerMode: 'screen',
        // headerTransitionPreset: 'fade-in-place',
    };
    return createStackNavigator(routeConfigs, navigatorConfig);
}

const allScreens = {
    ...TONLabsRoutes.routing.screens,
//    ...TONLegalNotes.routing.screens,
};

export default class StackNavigator extends Component {
    static allScreens() {
        return allScreens;
    }

    // Render
    render() {
        const {
            screens,
            initialRouteName,
            initialRouteParams,
        } = this.props;

        const configScreens = {};
        Object.keys(screens).map((screen) => {
            let path = PathMap.pathAndParamsForScreen(screen).path;
            if (path.startsWith('legal-notes?section=privacy-policy')) path = 'privacy-policy';
            if (path.startsWith('legal-notes?section=terms-of-use')) path = 'terms-of-use';
            if (path.startsWith('legal-notes?section=cookies-policy')) path = 'cookies-policy';
            configScreens[screen] = path;
        });

        return (
            <NavigationContainer linking={{
                prefixes: ['/'],
                config: configScreens,
            }}
            >
                <Stack.Navigator
                    initialRouteName={initialRouteName}
                    screenOptions={{
                        headerStyle: {
                            borderBottomWidth: 0,
                            title: 'Free TON',
                        },
                    }}
                >
                    {Object.keys(screens).map(scr =>
                        (<Stack.Screen
                            key={scr}
                            name={scr}
                            component={screens[scr].screen}
                            options={screens[scr].screen.navigationOptions}
                        />))}
                </Stack.Navigator>
            </NavigationContainer>
        );
    }
}

StackNavigator.defaultProps = {
    screens: allScreens,
    initialRouteParams: null,
};

StackNavigator.propTypes = {
    screens: PropTypes.instanceOf(Object),
    initialRouteName: PropTypes.string.isRequired,
    initialRouteParams: PropTypes.instanceOf(Object),
};

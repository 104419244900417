// @ts-nocheck
import React, {useState} from "react";
import { connect } from "react-redux";
import MetaDecorator from "../../components/utils/metaDecorator";
import { StyleSheet, View } from 'react-native-web';
import Config from '../../config';

import { UILabel, ColorVariants, TypographyVariants, useTheme } from '@tonlabs/uikit.themes';
import { UIStyle } from '@tonlabs/uikit.core';

import Screen from '../Screen/Screen';
import Card from '../../components/views/Card';
import CardValue from '../../components/views/CardValue';
import UIGrid from '../../components/views/UIGrid';
import UIGridColumn from '../../components/views/UIGridColumn';
import UIButtonGroup from '../../components/views/UIButtonGroup';
import PairsTable from './PairsTable';

const styles = StyleSheet.create({
});

const showStats = false;

const Content:React.FunctionComponent<{}> = (props) => {
    const theme = useTheme();
    const [isLarge, setIsLarge] = useState(false);
    const [grid, setGrid] = useState(null);
    const onGridLayout = () => {
        if (grid) {
            setIsLarge(grid.getColumns() === 8);
        }
    }
    return (
      <View style={{backgroundColor: theme[ColorVariants.BackgroundPrimary]}}>
        <MetaDecorator
          title="Flash Exchange"
          description=""
          keywords=""
        />
        <UIGrid
          type={UIGrid.Type.C8}
          ref={(ref)=>{setGrid(ref)}}
          onLayout={onGridLayout}
        >
          <UIGridColumn medium={isLarge ? 8 : 4}>
            <View style={{padding: 16}}>
                <View style={{marginTop: 90}} />
                <UILabel color={ColorVariants.TextPrimary} role={TypographyVariants.TitleHuge} style={{textAlign: 'center'}}>
                    {'Future of DeFi'}
                </UILabel>
                <View style={{marginTop: 80, width: grid ? grid.getCellWidth()*4 : '100%', alignSelf:'center'}}>
                    <UILabel color={ColorVariants.TextSecondary} role={TypographyVariants.ParagraphText}>
                        Flash Exchange or Flex is a Free TON decentralized and distributed limit order book (DLOB) with AMM and Liquidity Pools,
                        which takes a most common centralized exchange model: central limit order book (CLOB) and implements it on-chain via a
                        distributed smart contract model.
                    </UILabel>
                </View>
                <View style={{marginTop: 32}} />
                <UIButtonGroup direction={isLarge ? UIButtonGroup.Direction.Row : UIButtonGroup.Direction.Column}>
                    <Card
                        link={`${Config.flexUrl}?debot=mainnet`}
                        title={'Trade'}
                        text={'Trade quickly and cheaply.'}
                        buttonTitle={'Enter Exchange'}
                        style={UIStyle.common.flex()}
                    />
                    <Card
                        link={Config.poolUrl}
                        title={'Pool'}
                        text={'Provide liquidity for any Flex token.'}
                        buttonTitle={'Add liquidity'}
                        style={UIStyle.common.flex()}
                    />
                    <Card
                        link={Config.podcastUrl}
                        title={'Killer Whale Pod Cast'}
                        text={'Launchpad for new Flex projects.'}
                        buttonTitle={'View projects'}
                        style={UIStyle.common.flex()}
                    />
                </UIButtonGroup>
                {showStats &&
                      <View style={{marginTop: 64}}>
                        <CardValue title={'1,800,000'} text={'Total value locked'} style={{marginLeft: 16}}/>
                        <CardValue title={'23,000,000'} text={'Total trading volume'} style={{marginLeft: 16}}/>
                      </View>
                }
                <PairsTable />
              </View>
            </UIGridColumn>
          </UIGrid>
        <View style={{marginTop: 180}} />
      </View>
    );
};

class LandingScreen extends Screen {
    renderFullWidthContent = () => {
        return (
            <Content />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return ({
        isNarrow: state.controller.mobile,
        ...ownProps,
    });
};

export default connect(mapStateToProps)(LandingScreen);

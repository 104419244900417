// @flow
import React from 'react';

import {
    StyleSheet,
    View,
    ScrollView,
    Dimensions,
} from 'react-native-web';

import { store } from "../../redux/store/configure-store";

import { UIScreen } from '@tonlabs/uikit.navigation_legacy';
import { UIStyle, UIColor } from '@tonlabs/uikit.core';
import { UILabel, ColorVariants, TypographyVariants, } from '@tonlabs/uikit.themes';
import { UIBoxButton } from '@tonlabs/uikit.controls';

import Header from "../../components/views/header";
import Footer from '../../components/views/footer';
import Config from '../../config';

import { setMobile } from '../../redux/actions/ControllerActions';

const styles = StyleSheet.create({
});

class Screen<Props, State> extends UIScreen<> {
    static navigationOptions = ({ navigation, route }) => {
        return { headerShown: false, title: 'Flex' };
    }

    constructor(props: Props & ControllerProps) {
        super(props);

        this.state = {
            screenWidth: 0,
            scrollViewHeight: 0,
            scrollContentHeight: 0,
            contentWidth: 0,
            topBarDivider: false,
            scrollingToPosition: false,
        };

        this.scrollingOffset = 0;
    }

    componentDidMount() {
        super.componentDidMount();
    }

    dispatchNarrow(narrow: boolean) {
        store.dispatch(setMobile(narrow));
    }

    // Getters
    getContentWidth() {
        return this.state.contentWidth;
    }

    isNarrow() {
        return this.props.isNarrow;
    }

    // Events
    handleNavigation() {
    }

    onContentLayout = (e: any) => {
        const { height, width } = e.nativeEvent.layout;
        this.setStateSafely({ scrollContentHeight: height, contentWidth: width });
    }

    onScrollLayot = (e: any) => {
        this.setStateSafely({ scrollViewHeight: e.nativeEvent?.layout?.height });
    }

    onScroll = (e: any) => {
        const { contentOffset } = e.nativeEvent;
        const topBarDivider = (contentOffset.y > 0);

        if (this.state.topBarDivider != topBarDivider) {
            this.setStateSafely({ topBarDivider });
        }
    }

    renderAbsoluteContent() {
        return null;
    }

    renderBottom() {
        return null;
    }

    renderBottomNavigationBar() {
        return null;
    }

    // virtual
    renderFullWidthContent = () => {
        return null;
    }

    renderTopBar() {
        return <Header divider={this.state.topBarDivider} isNarrow={this.props.isNarrow}/>;
    }

    renderFooter() {
        return <Footer />;
    }

    isLoading() {
        return false;
    }

    renderLoading() {
        return (
            <View style={[
                   UIStyle.height.full(),
                   UIStyle.common.justifyCenter(),
                   UIStyle.common.alignCenter(),
                   UIColor.getBackgroundColorStyle(UIColor.white()),
               ]}
               >
                  <UIBoxButton
                      title={''}
                      loading
                      onPress={() => {}}
                  />
            </View>
        );
    }

    render() {
        if (this.isLoading()) {
            return this.renderLoading();
        }

        const windowHeight = Dimensions.get('window').height - (Config.isLandingPage ? 56 : 80);

        const contentContainerStyle = [];
        if (!windowHeight && !this.state.scrollContentHeight) {
            contentContainerStyle.push({ height: '100%' });
            contentContainerStyle.push(UIStyle.Common.justifySpaceBetween());
        } else if (this.state.scrollContentHeight <= windowHeight) {
            // short page style
            contentContainerStyle.push({ height: windowHeight });
            contentContainerStyle.push(UIStyle.common.justifySpaceBetween());
        }

        return (
          <React.Fragment>
              <View
                  style={[
                      UIStyle.common.flex(),
                  ]}
                  onLayout={e => {this.onScreenLayoutDefault(e);}}
              >
                  {this.renderTopBar()}
                  <ScrollView
                      onScroll={this.onScroll}
                      scrollEventThrottle={1}
                      onLayout={this.onScrollLayot}
                      ref={(component) => { this.scrollView = component; }}
                      style={[{height: windowHeight},]}
                      contentContainerStyle={contentContainerStyle}
                  >
                      <View onLayout={this.onContentLayout} style={contentContainerStyle}>
                          {this.renderFullWidthContent()}
                          {this.renderFooter()}
                      </View>
                  </ScrollView>
              </View>
          </React.Fragment>
        );
    }
}

export default Screen;

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import cn from "classnames";
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { StyleSheet, View, Text } from 'react-native-web';
import { UILabel, ColorVariants, TypographyVariants, useTheme } from '@tonlabs/uikit.themes';

import MetaDecorator from "../../components/utils/metaDecorator";
import { useDashboard } from "../../components/controllers/useDashboard";

import {
  PairInfo,
  DeBot
 } from "../../interfaces";

 import Screen from '../Screen/Screen';
 import Header from "../../components/views/header";
 import UIGrid from '../../components/views/UIGrid';
 import UIGridColumn from '../../components/views/UIGridColumn';

import { useOrderbook } from "../../components/controllers/useOrderbook";
import { useTrades } from "../../components/controllers/useTrades";
import { useCandlestick } from "../../components/controllers/useCandlestickChart";
import { useOrderbookLinearChart } from "../../components/controllers/useOrderbookLinearChart";
import { usePriceLinearChart } from "../../components/controllers/usePriceLinearChart";

import CandlestickChart from "../../components/views/charts/CandlestickChart";
import OrderbookLinearChart from "../../components/views/charts/OrderbookLinearChart";
import PriceLinearChart from "../../components/views/charts/PriceLinearChart";

const QRCode = require('qrcode.react');


const styles = StyleSheet.create({
});


export interface IOrderbookProps {
  selectedPair: PairInfo | undefined;
  selectedDeBot: DeBot | undefined;
}

const Orderbook:React.FunctionComponent<IOrderbookProps> = ({selectedPair, selectedDeBot}) => {
  const { orderbook, orderbookMax, selectOrder } = useOrderbook(selectedPair, selectedDeBot);

  return (orderbook && orderbookMax && orderbook.length) ?
    <div className="orderbook">
      {orderbook.map((row, index) => {
        const volume: number = row.buyVolume || row.sellVolume;
        const isBuy: boolean = row.buyVolume ? true : false;
        return (<Row key={index}>
          <Col>
            <div className="orderbook__row" onClick={() => selectOrder && selectOrder(row)}>
              <div className="price">{(row.price).toFixed(9)}</div>
              <div className="volume">{volume}</div>
              <div className={cn("graph", {"graph__negative": !isBuy, "graph__positive": isBuy})} style={{width: 40*row.price*volume/orderbookMax + '%'}}></div>
            </div>
          </Col>
        </Row>);
      })}
    </div>
  : (
  <div className="orderbook">
    {[...Array(7)].map((x, i) =>
      <Row key={i}>
        <Col>
          <div className="orderbook__row no-data">
            <div className="price"></div>
            <div className="volume"></div>
          </div>
        </Col>
      </Row>
    )}
  </div>
  );
}

const CandlestickChartComponent:React.FunctionComponent<IOrderbookProps> = ({selectedPair, selectedDeBot}) => {
  const { candlestickChart } = useCandlestick(selectedPair, selectedDeBot);

  return (<CandlestickChart data={candlestickChart} currency={selectedPair ? selectedPair.nameMajor : ''}/>);
}


const OrderbookChartComponent:React.FunctionComponent<IOrderbookProps> = ({selectedPair, selectedDeBot}) => {
  const { orderbookLinearChart } = useOrderbookLinearChart(selectedPair, selectedDeBot);

  return (<OrderbookLinearChart data={orderbookLinearChart} />);
}

const PriceChartComponent:React.FunctionComponent<IOrderbookProps> = ({selectedPair, selectedDeBot}) => {
  const { priceLinearChart } = usePriceLinearChart(selectedPair, selectedDeBot);

  return (<PriceLinearChart data={priceLinearChart} />);
}


const TradesHistory:React.FunctionComponent<IOrderbookProps> = ({selectedPair, selectedDeBot}) => {
  const { trades } = useTrades(selectedPair, selectedDeBot);
  return (trades && trades.length) ?
    <div className="trades">
      {trades.map((row, index) => {
        return (<Row key={index}>
          <Col>
            <div className="trades__row">
              <div className="price">{row.price}</div>
              <div className="volume">{row.volume}</div>
            </div>
          </Col>
        </Row>);
      })}
    </div>
  : (
  <div className="trades">
    {[...Array(7)].map((x, i) =>
      <Row key={i}>
        <Col>
          <div className="trades__row no-data">
            <div className="price"></div>
            <div className="volume"></div>
          </div>
        </Col>
      </Row>
    )}
  </div>
  );
}

const Main:React.FunctionComponent<{}> = () => {
  const {
    selectedPair,
    selectedDeBot,
    selectedOrderbookRow,

    setExchangeMode,
    exchangeMode,

    setChartMode,
    chartMode,

    setBuySellDeeplink,
    orderMessage
  } = useDashboard();
  const { trades } = useTrades(selectedPair, selectedDeBot);
  const [ update, setUpdate ] = useState<boolean>(false);
  const [ messageReady, setMessageReady ] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const validationSchema = Yup.object().shape({
    currency: Yup.number()
      .typeError('This one should be a number')
      .required(''),
      // .min(1, 'Minimum 1')
      // .max(5000, 'Maximum 5000'),
   totalPrice: Yup.number()
      .typeError('This one should be a number')
      .required(''),
      // .min(1, 'Minimum 1')
      // .max(5000, 'Maximum 5000'),
    currencyPrice: Yup.number()
      .typeError('This one should be a number')
      .required(''),
      // .min(1, 'Minimum 1')
      // .max(5000, 'Maximum 5000'),
    tokenAmount: Yup.number()
      .typeError('This one should be a number')
      .required('')
      // .min(1, 'Minimum 1')
      // .max(5000, 'Maximum 5000'),
  });
  const formik = useFormik({
    initialValues: {
      currencyPrice: '',
      totalPrice: '',
      currency: "-",
      tokensAmount: ''
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: ({currency}, { setSubmitting }) => {

    }
  });

  useEffect(() => {
    formik.values.currencyPrice = '';
    formik.values.tokensAmount = '';
    setMessageReady(false);
    setUpdate(!update);
  }, [selectedPair]);

  useEffect(() => {
    if (selectedOrderbookRow) {
      formik.values.currencyPrice = selectedOrderbookRow.price;
      formik.values.tokensAmount = selectedOrderbookRow.sellVolume || selectedOrderbookRow.buyVolume;
      setUpdate(!update);
    }
  }, [selectedOrderbookRow]);

  useEffect(() => {
    setMessageReady(false);
    if (selectedPair && formik.values.currencyPrice && formik.values.tokensAmount) {
      setBuySellDeeplink(
        selectedPair.tradingPair,
        (parseFloat(formik.values.currencyPrice)*(10**parseFloat(selectedPair.decimalsMinor))).toString(),
        (parseFloat(formik.values.tokensAmount)*(10**parseFloat(selectedPair.decimalsMajor))).toString(),
        () => setMessageReady(true)
        );
      }
  }, [formik.values, selectedOrderbookRow, exchangeMode]);

  const handleBuySellClick = () => {
    if(orderMessage && selectedDeBot) {
      setShowModal(true);
    }
  }

  const getUri = () => {
      if(orderMessage && selectedDeBot) {
        const flexDebot = "0:e57e4fa3f5ab829c12ce145ce24c0a9b4aa666a0e60d5b00f8bfd6aa1d90d64f";
        const netName = selectedDeBot.title.toLowerCase();
        const link = `https://uri.ton.surf/debot/${flexDebot}?message=${orderMessage.message}&net=${netName}`;
        return link;
      }
      return '';
  }

  const theme = useTheme();

  const handleContinueWebClick = () => {
      window.open(getUri(), "Popup","toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30");
  }

  const renderQR = () => {
      return (
          <QRCode
              id="uri-qr"
              value={getUri()}
              size={224}
          />
      );
  }

  const styleThemedPanel = {backgroundColor: theme[ColorVariants.BackgroundSecondary]};

    return (
      <>
      <MetaDecorator
        title="Flash Exchange"
        description=""
        keywords=""
      />
      <div id="dashboard">
      <Container fluid>
        <Row>
          <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 12, order: 1 }} lg={{ span: 12 }} xl={{ span: 6 }}>
            <div className="panel panel-main" style={styleThemedPanel}>

            <Tab.Container
                id="chart-tabs"
                defaultActiveKey={chartMode}
                onSelect={(activeKey) => {
                  setChartMode(String(activeKey));
                }}
              >
                <div className="header header-toggle">
                  <Nav variant="pills" className={cn("flex-row")}>
                    <Nav.Item>
                      <Nav.Link eventKey="chart">Chart</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="depth">Depth</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="price">Price</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link eventKey="tvl">TVL</Nav.Link>
                    </Nav.Item> */}
                  </Nav>
                </div>
                <Tab.Content>
                  <Tab.Pane eventKey="chart">
                    <CandlestickChartComponent selectedPair={selectedPair} selectedDeBot={selectedDeBot}/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="depth">
                    <OrderbookChartComponent selectedPair={selectedPair} selectedDeBot={selectedDeBot}/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="price">
                    <PriceChartComponent selectedPair={selectedPair} selectedDeBot={selectedDeBot}/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tvl">
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
          <Col xs={{ span: 12, order: 3 }} sm={{ span: 12, order: 3 }} md={{ span: 6, order: 2 }} lg={{ span: 6 }} xl={{ span: 3 }}>
            <div className="panel panel-orderbook" style={styleThemedPanel}>
              <Tab.Container
                id="orderbook-tabs"
                defaultActiveKey="orderbook"
              >
                <div className="header header-toggle">
                  <Nav variant="pills" className={cn("flex-row")}>
                    <Nav.Item>
                      <Nav.Link eventKey="orderbook">Orderbook</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="trades">Trades</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <Tab.Content>
                  <Tab.Pane eventKey="orderbook">
                    <div className="subheader">
                      <dl>
                        <dt>Price {selectedPair ? selectedPair.nameMinor : "-"}</dt>
                        <dd>Amount {selectedPair ? selectedPair.nameMajor : "-"}</dd>
                      </dl>
                    </div>
                    <Orderbook selectedPair={selectedPair} selectedDeBot={selectedDeBot}/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="trades">
                    <div className="subheader">
                      <dl>
                        <dt>Price {selectedPair ? selectedPair.nameMinor : "-"}</dt>
                        <dd>Amount {selectedPair ? selectedPair.nameMajor : "-"}</dd>
                      </dl>
                    </div>
                    <TradesHistory selectedPair={selectedPair} selectedDeBot={selectedDeBot}/>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
          <Col xs={{ span: 12, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 6, order: 3 }} lg={{ span: 6 }} xl={{ span: 3 }}>
            <div
              className={cn("panel", "panel-exchange", {"selected-buy": exchangeMode === 'buy', "selected-sell": exchangeMode === 'sell'})}
              style={styleThemedPanel}
            >
              <Tab.Container
                id="exchange-tabs"
                defaultActiveKey={exchangeMode}
                onSelect={(activeKey) => {
                  setExchangeMode(String(activeKey));
                }}
              >
                <div className="header header-toggle">
                  <Nav variant="pills" className={cn("flex-row")}>
                    <Nav.Item>
                      <Nav.Link eventKey="buy">Buy</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="sell">Sell</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>

                <div className="subheader">
                  <dl>
                    <dt>Last price</dt>
                    <dd>{trades && trades[0] && trades[0].price ? trades[0].price : '0.000 000 000'}</dd>
                  </dl>
                </div>
                <Row>
                  <Col>
                  <FormikProvider value={formik}>
                  <form onSubmit={formik.handleSubmit}>
                    <Tab.Content>
                      <Tab.Pane eventKey="buy">
                      </Tab.Pane>
                      <Tab.Pane eventKey="sell">
                      </Tab.Pane>
                    </Tab.Content>
                        <div className="form-group">
                          <label>
                            {(!formik.errors.currencyPrice && !!formik.values.currencyPrice) && <>Price {selectedPair ? selectedPair.nameMinor : "-"}</>}
                            {formik.errors.currencyPrice && <div className="invalid-feedback d-block">
                              {formik.errors.currencyPrice}
                            </div>}
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              disabled={!selectedPair}
                              name="currencyPrice"
                              className="form-control"
                              placeholder={"Price " + (selectedPair ? selectedPair.nameMinor : "-")}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.currencyPrice}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>
                            {(!formik.errors.tokensAmount && !!formik.values.tokensAmount) && <>Amount {selectedPair ? selectedPair.nameMajor : "-"}</>}
                            {formik.errors.tokensAmount && <div className="invalid-feedback d-block">
                              {formik.errors.tokensAmount}
                            </div>}
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              disabled={!selectedPair}
                              name="tokensAmount"
                              className="form-control"
                              placeholder={"Amount " + (selectedPair ? selectedPair.nameMajor : "-")}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.tokensAmount}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>
                            {(!formik.errors.currencyPrice && !!formik.values.currencyPrice) && <>Total {selectedPair ? selectedPair.nameMinor : "-"}</>}
                            {formik.errors.totalPrice && <div className="invalid-feedback d-block">
                              {formik.errors.totalPrice}
                            </div>}
                          </label>
                          <div className="input-group">
                            <input
                              readOnly
                              disabled={!selectedPair}
                              type="text"
                              name="totalPrice"
                              className="form-control"
                              placeholder={"Total " + (selectedPair ? selectedPair!.nameMinor : "-")}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.tokensAmount && formik.values.currencyPrice ? parseFloat(formik.values.tokensAmount)*parseFloat(formik.values.currencyPrice) : ''}
                            />
                          </div>
                        </div>
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={handleBuySellClick}
                          disabled={!messageReady}>
                          {exchangeMode === 'buy' ? "Buy" : "Sell" } {selectedPair ? (formik.values.tokensAmount !== '0' ? formik.values.tokensAmount : '') + ' ' + selectedPair.nameMajor : '-'}
                        </button>
                    </form>
                    </FormikProvider>
                    <Modal show={showModal} onHide={()=>setShowModal(false)}>
                        <Modal.Body>
                        <View style={{alignItems: 'center'}}>
                            {renderQR()}
                            <View style={{marginTop: 16}}/>
                            <Text>
                            {'OR...'}
                            </Text>
                            <View style={{marginTop: 16}}/>
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={handleContinueWebClick}>
                              {'Open Web Surf'}
                            </button>
                        </View>
                        </Modal.Body>
                    </Modal>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
      </div>
      </>
    );
  };

  const Content = () => {
      const theme = useTheme();
      const [isLarge, setIsLarge] = useState(false);
      const [grid, setGrid] = useState(null);
      const onGridLayout = () => {
          if (grid) {
              setIsLarge(grid.getColumns() === 12);
          }
      }
      return (
        <View>
          <MetaDecorator
            title="Flash Exchange"
            description=""
            keywords=""
          />
          <UIGrid
            type={UIGrid.Type.C12}
            ref={(ref)=>{setGrid(ref)}}
            onLayout={onGridLayout}
          >
              <UIGridColumn medium={12}>
                  <Main isLarge={isLarge} grid={grid}/>
              </UIGridColumn>
          </UIGrid>
          <View style={{marginTop: 180}} />
        </View>
      );
  }

class TradeScreen extends Screen {
      constructor(props: Props) {
          super(props);

          this.state = {
              screenWidth: 0,
              scrollViewHeight: 0,
              scrollContentHeight: 0,
              contentWidth: 0,
              topBarDivider: false,
          };
      }

      renderFullWidthContent = () => {
          return (
            <div className={cn("ws-app", {"isMobile": this.props.isNarrow, "index-page": true})}>
              <main>
                <Main />
              </main>
            </div>
          );
      }
  }

  const mapStateToProps = (state, ownProps) => {
      return ({
          isNarrow: state.controller.mobile,
          ...ownProps,
      });
  };

export default connect(mapStateToProps)(TradeScreen);

// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Container, Row, Col } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import { StyleSheet, View } from 'react-native-web';

import { UILabel, ColorVariants, TypographyVariants } from '@tonlabs/uikit.themes';
import { UIBoxButton, UILinkButtonType, UILinkButton, UILinkButtonIconPosition } from '@tonlabs/uikit.controls';
import { UIStyle } from '@tonlabs/uikit.core';
import { UIPopoverMenu } from '@tonlabs/uikit.navigation_legacy';
import { UIAssets } from '@tonlabs/uikit.assets';

import Config from '../../config';
import { useHeader } from "../../components/controllers/useHeader";
import DeBotClient, { DeBots } from "../../client/client";
import {
    setDeBots
} from "../../redux/actions";
import {
  PairInfo,
  DeBot
 } from "../../interfaces";

const styles = StyleSheet.create({
});

function getHistory(pair, debot, cb) {
    const then = (value: { history: any}) => {
        const payload = typeof value === 'string' ? JSON.parse(value) : value;
        cb(payload.history);
    }
    if (pair.type === "Tip3TonPairInfo") {
      DeBotClient.getPriceTickHistory({
        symbol: pair.symbolMajor,
        decimals: pair.decimalsMajor,
        tradingPair: pair.tradingPair
      }, debot)
      .then(then);
    } else {
      DeBotClient.getXchgPriceTickHistory({
        symbolMajor: pair.symbolMajor,
        symbolMinor: pair.symbolMinor,
        decimalsMajor: pair.decimalsMajor,
        decimalsMinor: pair.decimalsMinor,
        xchgTradingPair: pair.tradingPair
      }, debot)
      .then(then);
    }
}

function get24HPrice(pair, debot, cb) {
    const then = (value: { prices: any}) => {
        const payload = typeof value === 'string' ? JSON.parse(value) : value;
        const priceLinearChart = payload.prices;
        const data: any[] = [];
        for (const key in priceLinearChart) {
          data.push({
            date: moment.unix(parseInt(key)).format("YYYY/MM/DD HH:mm:ss"),
            price: parseFloat(DeBotClient.hexToUtf8(priceLinearChart[key].price)),
            volume  : parseFloat(DeBotClient.hexToUtf8(priceLinearChart[key].volume))
          });
        }
        cb(data);
    }
    if (pair.type === "Tip3TonPairInfo") {
        DeBotClient.getLinearChart({
              symbol: pair.symbolMajor,
              decimals: pair.decimalsMajor,
              tradingPair: pair.tradingPair
            },
            debot,
            moment().subtract(24, 'hours').unix(),
            moment().unix(),
            3600,
        )
        .then(then);
    } else {
        DeBotClient.getXchgLinearChart({
            symbolMajor: pair.symbolMajor,
            symbolMinor: pair.symbolMinor,
            decimalsMajor: pair.decimalsMajor,
            decimalsMinor: pair.decimalsMinor,
            xchgTradingPair: pair.tradingPair
          },
          debot,
          moment().subtract(24, 'hours').unix(),
          moment().unix(),
          3600,
      )
      .then(then);
  }
}

function getTrades(history) {
    let trades: any[] = [];
    for (const key in history) {
      for (let index = 0; index < history[key].length; index++) {
        trades.push({
          key: key,
          price: DeBotClient.hexToUtf8(history[key][index].price),
          volume: DeBotClient.hexToUtf8(history[key][index].volume)
        });
      }
    }
    trades = trades.reverse();
    return trades;
}

function getPriceDelta(prices) {
  let priceDelta: any = '-';
  const priceChange = getPriceChange(prices);
  if (prices && prices.length) {
      priceDelta = prices[0].price != 0 ? (priceChange / prices[0].price).toFixed(2) * 100 : '-';
  }
  return priceDelta;
}

function getPriceChange(prices) {
  let priceChange: any = '-';
  if (prices && prices.length) {
      priceChange = (prices[prices.length - 1].price - prices[0].price).toFixed(2);
  }
  return priceChange;
}

const DebotTable:React.FunctionComponent<{}> = (props) => {
    const { pairs, debots, selectedDeBot, setSelectedDeBot } = useHeader();
    const [popoverShown, setPopoverShown] = useState<boolean>(false);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setDeBots(DeBots));
    },[dispatch]);

    return (
      <Row>
        <Col xs={{ span: 0, order: 1 }} sm={{ span: 0, order: 1 }} md={{ span: 0, order: 1 }} lg={{ span: 3 }} xl={{ span: 3 }}/>
        <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 12, order: 1 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <View style={{marginTop: 64}} />
            {selectedDeBot &&
            <View style={{maxWidth: 105}}>
              <UIPopoverMenu
                placement="bottom"
                menuItemsList={
                  debots.map((debot, index) => ({
                    title: debot.title,
                    onPress: () => setSelectedDeBot(debot)
                }))}
              >
                <UILinkButton
                    icon={popoverShown ? UIAssets.icons.ui.unfoldHide : UIAssets.icons.ui.unfoldShow}
                    iconPosition={UILinkButtonIconPosition.Middle}
                    title={selectedDeBot.title}
                    type={UILinkButtonType.Menu}
                    onPress={() => setPopoverShown(!popoverShown)}
                />
              </UIPopoverMenu>
            </View>
            }

                <View style={[UIStyle.common.flexRow(), {borderBottomWidth: 1, borderBottomColor: 'rgb(223, 227, 235)'}, {marginTop: 8}]}>
                    <UILabel color={ColorVariants.TextPrimary} role={TypographyVariants.TitleSmall} style={UIStyle.common.flex()}>
                        {`Assets`}
                    </UILabel>
                    <UILabel color={ColorVariants.TextPrimary} role={TypographyVariants.TitleSmall} style={UIStyle.common.flex()}>
                        {`Last Trade`}
                    </UILabel>
                    <UILabel color={ColorVariants.TextPrimary} role={TypographyVariants.TitleSmall} style={UIStyle.common.flex()}>
                        {`24H %`}
                    </UILabel>
                    <UILabel color={ColorVariants.TextPrimary} role={TypographyVariants.TitleSmall} style={UIStyle.common.flex()}>
                        {`24H Change`}
                    </UILabel>
                    <UILabel color={ColorVariants.TextPrimary} role={TypographyVariants.TitleSmall} style={UIStyle.common.flex()}>
                        {``}
                    </UILabel>
                </View>
            {(!pairs || !pairs.length) &&
                <View style={{alignItems: 'center', flex:1, marginTop: 32}}>
                    <Spinner animation="border" variant="primary" />
                </View>
            }
            {pairs && pairs.map && pairs.map((pair, index) => <Pair key={pair.tradingPair} pair={pair} debot={selectedDeBot}/>)}
        </Col>
        <Col xs={{ span: 0, order: 1 }} sm={{ span: 0, order: 1 }} md={{ span: 0, order: 1 }} lg={{ span: 3 }} xl={{ span: 3 }}/>
      </Row>
    );
}

export type PairProps = {
  key: string;
  pair: PairInfo;
  debot: DeBot | undefined;
};

const Pair:React.FunctionComponent<PairProps> = (props: PairProps) => {
    const [history, setHistory] = useState<any>(null);
    const [prices, setPrices] = useState<any>([]);
    useEffect(() => {
      if (!prices || !prices.length)
          get24HPrice(props.pair, props.debot, (prices)=>{
              setPrices(prices);
          });
      if (!history)
          getHistory(props.pair, props.debot, (history)=>{
              setHistory(history);
          });
    });
    //prices
    let price24HDelta: any = getPriceDelta(prices);
    let price24HChange: any = getPriceChange(prices);
    //trades
    let trades: any[] = getTrades(history);
    let lastTrade = (trades && trades.length) ? trades[0].price*trades[0].volume : '-';

    const pairLink = `${Config.flexUrl}?debot=${props.debot.title.toLowerCase()}&tradingPair=${props.pair.tradingPair}`;

    return (
          <View style={[UIStyle.common.flexRow(), UIStyle.margin.topSmall()]}>
              <UILabel color={ColorVariants.TextSecondary} role={TypographyVariants.ParagraphText} style={UIStyle.common.flex()}>
                  {`${props.pair.nameMajor} / ${props.pair.nameMinor}`}
              </UILabel>
              <UILabel color={ColorVariants.TextSecondary} role={TypographyVariants.ParagraphText} style={UIStyle.common.flex()}>
                  {`${lastTrade}`}
              </UILabel>
              <UILabel color={ColorVariants.TextSecondary} role={TypographyVariants.ParagraphText} style={UIStyle.common.flex()}>
                  {`%${price24HDelta}`}
              </UILabel>
              <UILabel color={ColorVariants.TextSecondary} role={TypographyVariants.ParagraphText} style={UIStyle.common.flex()}>
                  {`${price24HChange}`}
              </UILabel>
              <UIBoxButton
                  onPress={()=>window.open(pairLink, '_blank')}
                  title={'Trade'}
              />
          </View>
    );
};

export default DebotTable;
